const apiBatch = {
  job: {
    findJobs: 'admin/job/findJobs',
    createJob: 'admin/job/createJob',
    updateJob: 'admin/job/updateJob',
    activeJob: 'admin/job/activeJob',
    inactiveJob: 'admin/job/inactiveJob',
    findJobHistory: 'admin/job/findJobHistory',
    retryJob: 'admin/job/retry',
  },
  batch: {
    findBatches: 'admin/batch/findBatches',
    createBatch: 'admin/batch/createBatch',
    updateBatch: 'admin/batch/updateBatch',
    activeBatch: 'admin/batch/activeBatch',
    inactiveBatch: 'admin/batch/inactiveBatch',
    findBatchHistory: 'admin/batch/findBatchHistory',
    retryBatch: 'admin/batch/retry',
    batchHistoryDetail: 'admin/batch/batchHistoryDetail',
  },
  runner: {
    findRunners: 'admin/runner/findRunners',
    createRunner: 'admin/runner/createRunner',
    updateRunner: 'admin/runner/updateRunner',
    activeRunner: 'admin/runner/activeRunner',
    inactiveRunner: 'admin/runner/inactiveRunner',
    findRunnerHistory: 'admin/runner/history',
    retryRunner: 'admin/runner/retry',
    runnerHistoryDetail: 'admin/runner/historyDetail',
  },
};
export default apiBatch;
