import React from 'react';
import i18next from 'i18next';
import './style.scss';

function Loader({ tran = false }) {
  return (
    <div
      className={tran ? 'loader-wrapper' : 'loader--container'}
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99999,
        backgroundColor: '#c4c4c',
        opacity: 0.8,
      }}
    >
      <div className='ripple'>
        <div></div>
        <div></div>
      </div>
      <h4 className='text'>{i18next.t('Loading')}</h4>
    </div>
  );
}
export default Loader;
