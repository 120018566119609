import DeviceDetector from 'device-detector-js';
import i18next from 'i18next';
import { config, helper, local } from 'services';
const axios = require('axios').default;
const deviceDetector = new DeviceDetector();

let request = {};
request.upload = async (url, formData, method = 'PUT') => {
  url = `${config.HOST + '/'}${url}`;
  let session = local.get('session') || '';
  let option = {
    method: method,
    data: formData,
    headers: {
      Authorization: `Bearer ${session.token || 'customer'}`,
      device: JSON.stringify(deviceDetector.parse(navigator.userAgent)),
    },
  };
  if (config.debug) console.log(`[POST]`, url, option);
  let res = await axios(url, option);
  if (res.status !== 200) {
    console.log(res);
    throw res.data;
  }
  if (config.debug) console.log(`[RESPONSE]`, url, res.data);
  return res.data;
};

request.request = async (
  url,
  data,
  headers,
  method = 'POST',
  isPublic = false
) => {
  try {
    let session = local.get('session') || '';
    url = `${config.HOST + '/'}${url}`;
    let option = {
      method, // or 'PUT'
      data, // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${session.token || 'customer'}`,
        device: JSON.stringify(deviceDetector.parse(navigator.userAgent)),
      },
    };
    option.headers = Object.assign({}, option.headers, headers);

    if (method === 'GET') {
      delete option.data;
      option.params = data;
    }

    if (config.debug) console.log(`[${method}]`, url, option);

    let res = await axios(url, option);

    switch (res.status) {
      case 401:
        helper.toast(
          'error',
          res.data.errorMsg || i18next.t('internalServerError')
        );
        window.location.href = '/#/login';
        break;
      case 403:
        helper.toast(
          'error',
          res.data.errorMsg || i18next.t("You don't have permission")
        );

        break;
      case 500:
        helper.toast(
          'error',
          res.data.errorMsg || i18next.t('internalServerError')
        );
        break;
      case 200:
        if (res.data && res.data.errorCode === 0) {
          return res.data;
        } else {
          helper.toast(
            'error',
            res.data.errorMsg || i18next.t('internalServerError')
          );
          break;
        }
      case 404:
        if (isPublic) {
          window.location.href = '/#/404';
        } else {
          helper.toast('error', res.data.errorMsg || i18next.t('dataNotFound'));
        }
        break;
      case 400:
        if (
          res.data.errorCode &&
          res.data.errorCode === 'E_MISSING_OR_INVALID_PARAMS'
        ) {
          helper.toast('error', 'Invalid parameters');
        } else {
          helper.toast('error', res.data.errorMsg || i18next.t('badRequest'));
        }
        break;
      default:
        throw res.data;
    }
  } catch (err) {
    helper.toast('error', i18next.t('internalServerError'));
    console.log('res', err);
    throw err;
  }
};
export default request;
