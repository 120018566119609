import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import metaDataReducer from './slice/metaDataSlice';
import workspaceReducer from './slice/workspaceSlice';
import layoutsReducer from './slice/layoutsSlice';
import commonPageSlice from './slice/commonPageSlice';
import persistsSlice from './slice/persitsSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['persists'],
};

const reducer = combineReducers({
  metaData: metaDataReducer,
  workspace: workspaceReducer,
  layouts: layoutsReducer,
  commonPage: commonPageSlice,
  persists: persistsSlice,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export default store;
