import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';

import App from './App';
import './assets/i18n/i18n';
import icons from './assets/icons';
// import 'antd/dist/antd.css'
import 'antd/dist/antd.min.css';
//redux-persist
import { PersistGate } from 'redux-persist/integration/react';

React.icons = icons;
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
