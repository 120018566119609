import {
  AccountBookFilled,
  AccountBookOutlined,
  AccountBookTwoTone,
  AimOutlined,
  AlertFilled,
  AlertOutlined,
  AlertTwoTone,
  AlibabaOutlined,
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  AlipayCircleFilled,
  AlipayCircleOutlined,
  AlipayOutlined,
  AlipaySquareFilled,
  AliwangwangFilled,
  AliwangwangOutlined,
  AliyunOutlined,
  AmazonCircleFilled,
  AmazonOutlined,
  AmazonSquareFilled,
  AndroidFilled,
  AndroidOutlined,
  AntCloudOutlined,
  AntDesignOutlined,
  ApartmentOutlined,
  ApiFilled,
  ApiOutlined,
  ApiTwoTone,
  AppleFilled,
  AppleOutlined,
  AppstoreAddOutlined,
  AppstoreFilled,
  AppstoreOutlined,
  AppstoreTwoTone,
  AreaChartOutlined,
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  ArrowsAltOutlined,
  AudioFilled,
  AudioMutedOutlined,
  AudioOutlined,
  AudioTwoTone,
  AuditOutlined,
  BackwardFilled,
  BackwardOutlined,
  BankFilled,
  BankOutlined,
  BankTwoTone,
  BarChartOutlined,
  BarcodeOutlined,
  BarsOutlined,
  BehanceCircleFilled,
  BehanceOutlined,
  BehanceSquareFilled,
  BehanceSquareOutlined,
  BellFilled,
  BellOutlined,
  BellTwoTone,
  BgColorsOutlined,
  BlockOutlined,
  BoldOutlined,
  BookFilled,
  BookOutlined,
  BookTwoTone,
  BorderBottomOutlined,
  BorderHorizontalOutlined,
  BorderInnerOutlined,
  BorderLeftOutlined,
  BorderOuterOutlined,
  BorderOutlined,
  BorderRightOutlined,
  BorderTopOutlined,
  BorderVerticleOutlined,
  BorderlessTableOutlined,
  BoxPlotFilled,
  BoxPlotOutlined,
  BoxPlotTwoTone,
  BranchesOutlined,
  BugFilled,
  BugOutlined,
  BugTwoTone,
  BuildFilled,
  BuildOutlined,
  BuildTwoTone,
  BulbFilled,
  BulbOutlined,
  BulbTwoTone,
  CalculatorFilled,
  CalculatorOutlined,
  CalculatorTwoTone,
  CalendarFilled,
  CalendarOutlined,
  CalendarTwoTone,
  CameraFilled,
  CameraOutlined,
  CameraTwoTone,
  CarFilled,
  CarOutlined,
  CarTwoTone,
  CaretDownFilled,
  CaretDownOutlined,
  CaretLeftFilled,
  CaretLeftOutlined,
  CaretRightFilled,
  CaretRightOutlined,
  CaretUpFilled,
  CaretUpOutlined,
  CarryOutFilled,
  CarryOutOutlined,
  CarryOutTwoTone,
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckCircleTwoTone,
  CheckOutlined,
  CheckSquareFilled,
  CheckSquareOutlined,
  CheckSquareTwoTone,
  ChromeFilled,
  ChromeOutlined,
  CiCircleFilled,
  CiCircleOutlined,
  CiCircleTwoTone,
  CiOutlined,
  CiTwoTone,
  ClearOutlined,
  ClockCircleFilled,
  ClockCircleOutlined,
  ClockCircleTwoTone,
  CloseCircleFilled,
  CloseCircleOutlined,
  CloseCircleTwoTone,
  CloseOutlined,
  CloseSquareFilled,
  CloseSquareOutlined,
  CloseSquareTwoTone,
  CloudDownloadOutlined,
  CloudFilled,
  CloudOutlined,
  CloudServerOutlined,
  CloudSyncOutlined,
  CloudTwoTone,
  CloudUploadOutlined,
  ClusterOutlined,
  CodeFilled,
  CodeOutlined,
  CodeSandboxCircleFilled,
  CodeSandboxOutlined,
  CodeSandboxSquareFilled,
  CodeTwoTone,
  CodepenCircleFilled,
  CodepenCircleOutlined,
  CodepenOutlined,
  CodepenSquareFilled,
  CoffeeOutlined,
  ColumnHeightOutlined,
  ColumnWidthOutlined,
  CommentOutlined,
  CompassFilled,
  CompassOutlined,
  CompassTwoTone,
  CompressOutlined,
  ConsoleSqlOutlined,
  ContactsFilled,
  ContactsOutlined,
  ContactsTwoTone,
  ContainerFilled,
  ContainerOutlined,
  ContainerTwoTone,
  ControlFilled,
  ControlOutlined,
  ControlTwoTone,
  CopyFilled,
  CopyOutlined,
  CopyTwoTone,
  CopyrightCircleFilled,
  CopyrightCircleOutlined,
  CopyrightCircleTwoTone,
  CopyrightOutlined,
  CopyrightTwoTone,
  CreditCardFilled,
  CreditCardOutlined,
  CreditCardTwoTone,
  CrownFilled,
  CrownOutlined,
  CrownTwoTone,
  CustomerServiceFilled,
  CustomerServiceOutlined,
  CustomerServiceTwoTone,
  DashOutlined,
  DashboardFilled,
  DashboardOutlined,
  DashboardTwoTone,
  DatabaseFilled,
  DatabaseOutlined,
  DatabaseTwoTone,
  DeleteColumnOutlined,
  DeleteFilled,
  DeleteOutlined,
  DeleteRowOutlined,
  DeleteTwoTone,
  DeliveredProcedureOutlined,
  DeploymentUnitOutlined,
  DesktopOutlined,
  DiffFilled,
  DiffOutlined,
  DiffTwoTone,
  DingdingOutlined,
  DingtalkCircleFilled,
  DingtalkOutlined,
  DingtalkSquareFilled,
  DisconnectOutlined,
  DislikeFilled,
  DislikeOutlined,
  DislikeTwoTone,
  DollarCircleFilled,
  DollarCircleOutlined,
  DollarCircleTwoTone,
  DollarOutlined,
  DollarTwoTone,
  DotChartOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  DownCircleFilled,
  DownCircleOutlined,
  DownCircleTwoTone,
  DownOutlined,
  DownSquareFilled,
  DownSquareOutlined,
  DownSquareTwoTone,
  DownloadOutlined,
  DragOutlined,
  DribbbleCircleFilled,
  DribbbleOutlined,
  DribbbleSquareFilled,
  DribbbleSquareOutlined,
  DropboxCircleFilled,
  DropboxOutlined,
  DropboxSquareFilled,
  EditFilled,
  EditOutlined,
  EditTwoTone,
  EllipsisOutlined,
  EnterOutlined,
  EnvironmentFilled,
  EnvironmentOutlined,
  EnvironmentTwoTone,
  EuroCircleFilled,
  EuroCircleOutlined,
  EuroCircleTwoTone,
  EuroOutlined,
  EuroTwoTone,
  ExceptionOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
  ExclamationOutlined,
  ExpandAltOutlined,
  ExpandOutlined,
  ExperimentFilled,
  ExperimentOutlined,
  ExperimentTwoTone,
  ExportOutlined,
  EyeFilled,
  EyeInvisibleFilled,
  EyeInvisibleOutlined,
  EyeInvisibleTwoTone,
  EyeOutlined,
  EyeTwoTone,
  FacebookFilled,
  FacebookOutlined,
  FallOutlined,
  FastBackwardFilled,
  FastBackwardOutlined,
  FastForwardFilled,
  FastForwardOutlined,
  FieldBinaryOutlined,
  FieldNumberOutlined,
  FieldStringOutlined,
  FieldTimeOutlined,
  FileAddFilled,
  FileAddOutlined,
  FileAddTwoTone,
  FileDoneOutlined,
  FileExcelFilled,
  FileExcelOutlined,
  FileExcelTwoTone,
  FileExclamationFilled,
  FileExclamationOutlined,
  FileExclamationTwoTone,
  FileFilled,
  FileGifOutlined,
  FileImageFilled,
  FileImageOutlined,
  FileImageTwoTone,
  FileJpgOutlined,
  FileMarkdownFilled,
  FileMarkdownOutlined,
  FileMarkdownTwoTone,
  FileOutlined,
  FilePdfFilled,
  FilePdfOutlined,
  FilePdfTwoTone,
  FilePptFilled,
  FilePptOutlined,
  FilePptTwoTone,
  FileProtectOutlined,
  FileSearchOutlined,
  FileSyncOutlined,
  FileTextFilled,
  FileTextOutlined,
  FileTextTwoTone,
  FileTwoTone,
  FileUnknownFilled,
  FileUnknownOutlined,
  FileUnknownTwoTone,
  FileWordFilled,
  FileWordOutlined,
  FileWordTwoTone,
  FileZipFilled,
  FileZipOutlined,
  FileZipTwoTone,
  FilterFilled,
  FilterOutlined,
  FilterTwoTone,
  FireFilled,
  FireOutlined,
  FireTwoTone,
  FlagFilled,
  FlagOutlined,
  FlagTwoTone,
  FolderAddFilled,
  FolderAddOutlined,
  FolderAddTwoTone,
  FolderFilled,
  FolderOpenFilled,
  FolderOpenOutlined,
  FolderOpenTwoTone,
  FolderOutlined,
  FolderTwoTone,
  FolderViewOutlined,
  FontColorsOutlined,
  FontSizeOutlined,
  ForkOutlined,
  FormOutlined,
  FormatPainterFilled,
  FormatPainterOutlined,
  ForwardFilled,
  ForwardOutlined,
  FrownFilled,
  FrownOutlined,
  FrownTwoTone,
  FullscreenExitOutlined,
  FullscreenOutlined,
  FunctionOutlined,
  FundFilled,
  FundOutlined,
  FundProjectionScreenOutlined,
  FundTwoTone,
  FundViewOutlined,
  FunnelPlotFilled,
  FunnelPlotOutlined,
  FunnelPlotTwoTone,
  GatewayOutlined,
  GifOutlined,
  GiftFilled,
  GiftOutlined,
  GiftTwoTone,
  GithubFilled,
  GithubOutlined,
  GitlabFilled,
  GitlabOutlined,
  GlobalOutlined,
  GoldFilled,
  GoldOutlined,
  GoldTwoTone,
  GoldenFilled,
  GoogleCircleFilled,
  GoogleOutlined,
  GooglePlusCircleFilled,
  GooglePlusOutlined,
  GooglePlusSquareFilled,
  GoogleSquareFilled,
  GroupOutlined,
  HddFilled,
  HddOutlined,
  HddTwoTone,
  HeartFilled,
  HeartOutlined,
  HeartTwoTone,
  HeatMapOutlined,
  HighlightFilled,
  HighlightOutlined,
  HighlightTwoTone,
  HistoryOutlined,
  HolderOutlined,
  HomeFilled,
  HomeOutlined,
  HomeTwoTone,
  HourglassFilled,
  HourglassOutlined,
  HourglassTwoTone,
  Html5Filled,
  Html5Outlined,
  Html5TwoTone,
  IdcardFilled,
  IdcardOutlined,
  IdcardTwoTone,
  IeCircleFilled,
  IeOutlined,
  IeSquareFilled,
  ImportOutlined,
  InboxOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
  InfoCircleTwoTone,
  InfoOutlined,
  InsertRowAboveOutlined,
  InsertRowBelowOutlined,
  InsertRowLeftOutlined,
  InsertRowRightOutlined,
  InstagramFilled,
  InstagramOutlined,
  InsuranceFilled,
  InsuranceOutlined,
  InsuranceTwoTone,
  InteractionFilled,
  InteractionOutlined,
  InteractionTwoTone,
  IssuesCloseOutlined,
  ItalicOutlined,
  KeyOutlined,
  LaptopOutlined,
  LayoutFilled,
  LayoutOutlined,
  LayoutTwoTone,
  LeftCircleFilled,
  LeftCircleOutlined,
  LeftCircleTwoTone,
  LeftOutlined,
  LeftSquareFilled,
  LeftSquareOutlined,
  LeftSquareTwoTone,
  LikeFilled,
  LikeOutlined,
  LikeTwoTone,
  LineChartOutlined,
  LineHeightOutlined,
  LineOutlined,
  LinkOutlined,
  LinkedinFilled,
  LinkedinOutlined,
  Loading3QuartersOutlined,
  LoadingOutlined,
  LockFilled,
  LockOutlined,
  LockTwoTone,
  LoginOutlined,
  LogoutOutlined,
  MacCommandFilled,
  MacCommandOutlined,
  MailFilled,
  MailOutlined,
  MailTwoTone,
  ManOutlined,
  MedicineBoxFilled,
  MedicineBoxOutlined,
  MedicineBoxTwoTone,
  MediumCircleFilled,
  MediumOutlined,
  MediumSquareFilled,
  MediumWorkmarkOutlined,
  MehFilled,
  MehOutlined,
  MehTwoTone,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  MergeCellsOutlined,
  MessageFilled,
  MessageOutlined,
  MessageTwoTone,
  MinusCircleFilled,
  MinusCircleOutlined,
  MinusCircleTwoTone,
  MinusOutlined,
  MinusSquareFilled,
  MinusSquareOutlined,
  MinusSquareTwoTone,
  MobileFilled,
  MobileOutlined,
  MobileTwoTone,
  MoneyCollectFilled,
  MoneyCollectOutlined,
  MoneyCollectTwoTone,
  MonitorOutlined,
  MoreOutlined,
  NodeCollapseOutlined,
  NodeExpandOutlined,
  NodeIndexOutlined,
  NotificationFilled,
  NotificationOutlined,
  NotificationTwoTone,
  NumberOutlined,
  OneToOneOutlined,
  OrderedListOutlined,
  PaperClipOutlined,
  PartitionOutlined,
  PauseCircleFilled,
  PauseCircleOutlined,
  PauseCircleTwoTone,
  PauseOutlined,
  PayCircleFilled,
  PayCircleOutlined,
  PercentageOutlined,
  PhoneFilled,
  PhoneOutlined,
  PhoneTwoTone,
  PicCenterOutlined,
  PicLeftOutlined,
  PicRightOutlined,
  PictureFilled,
  PictureOutlined,
  PictureTwoTone,
  PieChartFilled,
  PieChartOutlined,
  PieChartTwoTone,
  PlayCircleFilled,
  PlayCircleOutlined,
  PlayCircleTwoTone,
  PlaySquareFilled,
  PlaySquareOutlined,
  PlaySquareTwoTone,
  PlusCircleFilled,
  PlusCircleOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  PlusSquareFilled,
  PlusSquareOutlined,
  PlusSquareTwoTone,
  PoundCircleFilled,
  PoundCircleOutlined,
  PoundCircleTwoTone,
  PoundOutlined,
  PoweroffOutlined,
  PrinterFilled,
  PrinterOutlined,
  PrinterTwoTone,
  ProfileFilled,
  ProfileOutlined,
  ProfileTwoTone,
  ProjectFilled,
  ProjectOutlined,
  ProjectTwoTone,
  PropertySafetyFilled,
  PropertySafetyOutlined,
  PropertySafetyTwoTone,
  PullRequestOutlined,
  PushpinFilled,
  PushpinOutlined,
  PushpinTwoTone,
  QqCircleFilled,
  QqOutlined,
  QqSquareFilled,
  QrcodeOutlined,
  QuestionCircleFilled,
  QuestionCircleOutlined,
  QuestionCircleTwoTone,
  QuestionOutlined,
  RadarChartOutlined,
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusSettingOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
  ReadFilled,
  ReadOutlined,
  ReconciliationFilled,
  ReconciliationOutlined,
  ReconciliationTwoTone,
  RedEnvelopeFilled,
  RedEnvelopeOutlined,
  RedEnvelopeTwoTone,
  RedditCircleFilled,
  RedditOutlined,
  RedditSquareFilled,
  RedoOutlined,
  ReloadOutlined,
  RestFilled,
  RestOutlined,
  RestTwoTone,
  RetweetOutlined,
  RightCircleFilled,
  RightCircleOutlined,
  RightCircleTwoTone,
  RightOutlined,
  RightSquareFilled,
  RightSquareOutlined,
  RightSquareTwoTone,
  RiseOutlined,
  RobotFilled,
  RobotOutlined,
  RocketFilled,
  RocketOutlined,
  RocketTwoTone,
  RollbackOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SafetyCertificateFilled,
  SafetyCertificateOutlined,
  SafetyCertificateTwoTone,
  SafetyOutlined,
  SaveFilled,
  SaveOutlined,
  SaveTwoTone,
  ScanOutlined,
  ScheduleFilled,
  ScheduleOutlined,
  ScheduleTwoTone,
  ScissorOutlined,
  SearchOutlined,
  SecurityScanFilled,
  SecurityScanOutlined,
  SecurityScanTwoTone,
  SelectOutlined,
  SendOutlined,
  SettingFilled,
  SettingOutlined,
  SettingTwoTone,
  ShakeOutlined,
  ShareAltOutlined,
  ShopFilled,
  ShopOutlined,
  ShopTwoTone,
  ShoppingCartOutlined,
  ShoppingFilled,
  ShoppingOutlined,
  ShoppingTwoTone,
  ShrinkOutlined,
  SignalFilled,
  SisternodeOutlined,
  SketchCircleFilled,
  SketchOutlined,
  SketchSquareFilled,
  SkinFilled,
  SkinOutlined,
  SkinTwoTone,
  SkypeFilled,
  SkypeOutlined,
  SlackCircleFilled,
  SlackOutlined,
  SlackSquareFilled,
  SlackSquareOutlined,
  SlidersFilled,
  SlidersOutlined,
  SlidersTwoTone,
  SmallDashOutlined,
  SmileFilled,
  SmileOutlined,
  SmileTwoTone,
  SnippetsFilled,
  SnippetsOutlined,
  SnippetsTwoTone,
  SolutionOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  SoundFilled,
  SoundOutlined,
  SoundTwoTone,
  SplitCellsOutlined,
  StarFilled,
  StarOutlined,
  StarTwoTone,
  StepBackwardFilled,
  StepBackwardOutlined,
  StepForwardFilled,
  StepForwardOutlined,
  StockOutlined,
  StopFilled,
  StopOutlined,
  StopTwoTone,
  StrikethroughOutlined,
  SubnodeOutlined,
  SwapLeftOutlined,
  SwapOutlined,
  SwapRightOutlined,
  SwitcherFilled,
  SwitcherOutlined,
  SwitcherTwoTone,
  SyncOutlined,
  TableOutlined,
  TabletFilled,
  TabletOutlined,
  TabletTwoTone,
  TagFilled,
  TagOutlined,
  TagTwoTone,
  TagsFilled,
  TagsOutlined,
  TagsTwoTone,
  TaobaoCircleFilled,
  TaobaoCircleOutlined,
  TaobaoOutlined,
  TaobaoSquareFilled,
  TeamOutlined,
  ThunderboltFilled,
  ThunderboltOutlined,
  ThunderboltTwoTone,
  ToTopOutlined,
  ToolFilled,
  ToolOutlined,
  ToolTwoTone,
  TrademarkCircleFilled,
  TrademarkCircleOutlined,
  TrademarkCircleTwoTone,
  TrademarkOutlined,
  TransactionOutlined,
  TranslationOutlined,
  TrophyFilled,
  TrophyOutlined,
  TrophyTwoTone,
  TwitterCircleFilled,
  TwitterOutlined,
  TwitterSquareFilled,
  UnderlineOutlined,
  UndoOutlined,
  UngroupOutlined,
  UnlockFilled,
  UnlockOutlined,
  UnlockTwoTone,
  UnorderedListOutlined,
  UpCircleFilled,
  UpCircleOutlined,
  UpCircleTwoTone,
  UpOutlined,
  UpSquareFilled,
  UpSquareOutlined,
  UpSquareTwoTone,
  UploadOutlined,
  UsbFilled,
  UsbOutlined,
  UsbTwoTone,
  UserAddOutlined,
  UserDeleteOutlined,
  UserOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
  VerifiedOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignMiddleOutlined,
  VerticalAlignTopOutlined,
  VerticalLeftOutlined,
  VerticalRightOutlined,
  VideoCameraAddOutlined,
  VideoCameraFilled,
  VideoCameraOutlined,
  VideoCameraTwoTone,
  WalletFilled,
  WalletOutlined,
  WalletTwoTone,
  WarningFilled,
  WarningOutlined,
  WarningTwoTone,
  WechatFilled,
  WechatOutlined,
  WeiboCircleFilled,
  WeiboCircleOutlined,
  WeiboOutlined,
  WeiboSquareFilled,
  WeiboSquareOutlined,
  WhatsAppOutlined,
  WifiOutlined,
  WindowsFilled,
  WindowsOutlined,
  WomanOutlined,
  YahooFilled,
  YahooOutlined,
  YoutubeFilled,
  YoutubeOutlined,
  YuqueFilled,
  YuqueOutlined,
  ZhihuCircleFilled,
  ZhihuOutlined,
  ZhihuSquareFilled,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
export const Icons = {
  AccountBookFilled: <AccountBookFilled />,
  AccountBookOutlined: <AccountBookOutlined />,
  AccountBookTwoTone: <AccountBookTwoTone />,
  AimOutlined: <AimOutlined />,
  AlertFilled: <AlertFilled />,
  AlertOutlined: <AlertOutlined />,
  AlertTwoTone: <AlertTwoTone />,
  AlibabaOutlined: <AlibabaOutlined />,
  AlignCenterOutlined: <AlignCenterOutlined />,
  AlignLeftOutlined: <AlignLeftOutlined />,
  AlignRightOutlined: <AlignRightOutlined />,
  AlipayCircleFilled: <AlipayCircleFilled />,
  AlipayCircleOutlined: <AlipayCircleOutlined />,
  AlipayOutlined: <AlipayOutlined />,
  AlipaySquareFilled: <AlipaySquareFilled />,
  AliwangwangFilled: <AliwangwangFilled />,
  AliwangwangOutlined: <AliwangwangOutlined />,
  AliyunOutlined: <AliyunOutlined />,
  AmazonCircleFilled: <AmazonCircleFilled />,
  AmazonOutlined: <AmazonOutlined />,
  AmazonSquareFilled: <AmazonSquareFilled />,
  AndroidFilled: <AndroidFilled />,
  AndroidOutlined: <AndroidOutlined />,
  AntCloudOutlined: <AntCloudOutlined />,
  AntDesignOutlined: <AntDesignOutlined />,
  ApartmentOutlined: <ApartmentOutlined />,
  ApiFilled: <ApiFilled />,
  ApiOutlined: <ApiOutlined />,
  ApiTwoTone: <ApiTwoTone />,
  AppleFilled: <AppleFilled />,
  AppleOutlined: <AppleOutlined />,
  AppstoreAddOutlined: <AppstoreAddOutlined />,
  AppstoreFilled: <AppstoreFilled />,
  AppstoreOutlined: <AppstoreOutlined />,
  AppstoreTwoTone: <AppstoreTwoTone />,
  AreaChartOutlined: <AreaChartOutlined />,
  ArrowDownOutlined: <ArrowDownOutlined />,
  ArrowLeftOutlined: <ArrowLeftOutlined />,
  ArrowRightOutlined: <ArrowRightOutlined />,
  ArrowUpOutlined: <ArrowUpOutlined />,
  ArrowsAltOutlined: <ArrowsAltOutlined />,
  AudioFilled: <AudioFilled />,
  AudioMutedOutlined: <AudioMutedOutlined />,
  AudioOutlined: <AudioOutlined />,
  AudioTwoTone: <AudioTwoTone />,
  AuditOutlined: <AuditOutlined />,
  BackwardFilled: <BackwardFilled />,
  BackwardOutlined: <BackwardOutlined />,
  BankFilled: <BankFilled />,
  BankOutlined: <BankOutlined />,
  BankTwoTone: <BankTwoTone />,
  BarChartOutlined: <BarChartOutlined />,
  BarcodeOutlined: <BarcodeOutlined />,
  BarsOutlined: <BarsOutlined />,
  BehanceCircleFilled: <BehanceCircleFilled />,
  BehanceOutlined: <BehanceOutlined />,
  BehanceSquareFilled: <BehanceSquareFilled />,
  BehanceSquareOutlined: <BehanceSquareOutlined />,
  BellFilled: <BellFilled />,
  BellOutlined: <BellOutlined />,
  BellTwoTone: <BellTwoTone />,
  BgColorsOutlined: <BgColorsOutlined />,
  BlockOutlined: <BlockOutlined />,
  BoldOutlined: <BoldOutlined />,
  BookFilled: <BookFilled />,
  BookOutlined: <BookOutlined />,
  BookTwoTone: <BookTwoTone />,
  BorderBottomOutlined: <BorderBottomOutlined />,
  BorderHorizontalOutlined: <BorderHorizontalOutlined />,
  BorderInnerOutlined: <BorderInnerOutlined />,
  BorderLeftOutlined: <BorderLeftOutlined />,
  BorderOuterOutlined: <BorderOuterOutlined />,
  BorderOutlined: <BorderOutlined />,
  BorderRightOutlined: <BorderRightOutlined />,
  BorderTopOutlined: <BorderTopOutlined />,
  BorderVerticleOutlined: <BorderVerticleOutlined />,
  BorderlessTableOutlined: <BorderlessTableOutlined />,
  BoxPlotFilled: <BoxPlotFilled />,
  BoxPlotOutlined: <BoxPlotOutlined />,
  BoxPlotTwoTone: <BoxPlotTwoTone />,
  BranchesOutlined: <BranchesOutlined />,
  BugFilled: <BugFilled />,
  BugOutlined: <BugOutlined />,
  BugTwoTone: <BugTwoTone />,
  BuildFilled: <BuildFilled />,
  BuildOutlined: <BuildOutlined />,
  BuildTwoTone: <BuildTwoTone />,
  BulbFilled: <BulbFilled />,
  BulbOutlined: <BulbOutlined />,
  BulbTwoTone: <BulbTwoTone />,
  CalculatorFilled: <CalculatorFilled />,
  CalculatorOutlined: <CalculatorOutlined />,
  CalculatorTwoTone: <CalculatorTwoTone />,
  CalendarFilled: <CalendarFilled />,
  CalendarOutlined: <CalendarOutlined />,
  CalendarTwoTone: <CalendarTwoTone />,
  CameraFilled: <CameraFilled />,
  CameraOutlined: <CameraOutlined />,
  CameraTwoTone: <CameraTwoTone />,
  CarFilled: <CarFilled />,
  CarOutlined: <CarOutlined />,
  CarTwoTone: <CarTwoTone />,
  CaretDownFilled: <CaretDownFilled />,
  CaretDownOutlined: <CaretDownOutlined />,
  CaretLeftFilled: <CaretLeftFilled />,
  CaretLeftOutlined: <CaretLeftOutlined />,
  CaretRightFilled: <CaretRightFilled />,
  CaretRightOutlined: <CaretRightOutlined />,
  CaretUpFilled: <CaretUpFilled />,
  CaretUpOutlined: <CaretUpOutlined />,
  CarryOutFilled: <CarryOutFilled />,
  CarryOutOutlined: <CarryOutOutlined />,
  CarryOutTwoTone: <CarryOutTwoTone />,
  CheckCircleFilled: <CheckCircleFilled />,
  CheckCircleOutlined: <CheckCircleOutlined />,
  CheckCircleTwoTone: <CheckCircleTwoTone />,
  CheckOutlined: <CheckOutlined />,
  CheckSquareFilled: <CheckSquareFilled />,
  CheckSquareOutlined: <CheckSquareOutlined />,
  CheckSquareTwoTone: <CheckSquareTwoTone />,
  ChromeFilled: <ChromeFilled />,
  ChromeOutlined: <ChromeOutlined />,
  CiCircleFilled: <CiCircleFilled />,
  CiCircleOutlined: <CiCircleOutlined />,
  CiCircleTwoTone: <CiCircleTwoTone />,
  CiOutlined: <CiOutlined />,
  CiTwoTone: <CiTwoTone />,
  ClearOutlined: <ClearOutlined />,
  ClockCircleFilled: <ClockCircleFilled />,
  ClockCircleOutlined: <ClockCircleOutlined />,
  ClockCircleTwoTone: <ClockCircleTwoTone />,
  CloseCircleFilled: <CloseCircleFilled />,
  CloseCircleOutlined: <CloseCircleOutlined />,
  CloseCircleTwoTone: <CloseCircleTwoTone />,
  CloseOutlined: <CloseOutlined />,
  CloseSquareFilled: <CloseSquareFilled />,
  CloseSquareOutlined: <CloseSquareOutlined />,
  CloseSquareTwoTone: <CloseSquareTwoTone />,
  CloudDownloadOutlined: <CloudDownloadOutlined />,
  CloudFilled: <CloudFilled />,
  CloudOutlined: <CloudOutlined />,
  CloudServerOutlined: <CloudServerOutlined />,
  CloudSyncOutlined: <CloudSyncOutlined />,
  CloudTwoTone: <CloudTwoTone />,
  CloudUploadOutlined: <CloudUploadOutlined />,
  ClusterOutlined: <ClusterOutlined />,
  CodeFilled: <CodeFilled />,
  CodeOutlined: <CodeOutlined />,
  CodeSandboxCircleFilled: <CodeSandboxCircleFilled />,
  CodeSandboxOutlined: <CodeSandboxOutlined />,
  CodeSandboxSquareFilled: <CodeSandboxSquareFilled />,
  CodeTwoTone: <CodeTwoTone />,
  CodepenCircleFilled: <CodepenCircleFilled />,
  CodepenCircleOutlined: <CodepenCircleOutlined />,
  CodepenOutlined: <CodepenOutlined />,
  CodepenSquareFilled: <CodepenSquareFilled />,
  CoffeeOutlined: <CoffeeOutlined />,
  ColumnHeightOutlined: <ColumnHeightOutlined />,
  ColumnWidthOutlined: <ColumnWidthOutlined />,
  CommentOutlined: <CommentOutlined />,
  CompassFilled: <CompassFilled />,
  CompassOutlined: <CompassOutlined />,
  CompassTwoTone: <CompassTwoTone />,
  CompressOutlined: <CompressOutlined />,
  ConsoleSqlOutlined: <ConsoleSqlOutlined />,
  ContactsFilled: <ContactsFilled />,
  ContactsOutlined: <ContactsOutlined />,
  ContactsTwoTone: <ContactsTwoTone />,
  ContainerFilled: <ContainerFilled />,
  ContainerOutlined: <ContainerOutlined />,
  ContainerTwoTone: <ContainerTwoTone />,
  ControlFilled: <ControlFilled />,
  ControlOutlined: <ControlOutlined />,
  ControlTwoTone: <ControlTwoTone />,
  CopyFilled: <CopyFilled />,
  CopyOutlined: <CopyOutlined />,
  CopyTwoTone: <CopyTwoTone />,
  CopyrightCircleFilled: <CopyrightCircleFilled />,
  CopyrightCircleOutlined: <CopyrightCircleOutlined />,
  CopyrightCircleTwoTone: <CopyrightCircleTwoTone />,
  CopyrightOutlined: <CopyrightOutlined />,
  CopyrightTwoTone: <CopyrightTwoTone />,
  CreditCardFilled: <CreditCardFilled />,
  CreditCardOutlined: <CreditCardOutlined />,
  CreditCardTwoTone: <CreditCardTwoTone />,
  CrownFilled: <CrownFilled />,
  CrownOutlined: <CrownOutlined />,
  CrownTwoTone: <CrownTwoTone />,
  CustomerServiceFilled: <CustomerServiceFilled />,
  CustomerServiceOutlined: <CustomerServiceOutlined />,
  CustomerServiceTwoTone: <CustomerServiceTwoTone />,
  DashOutlined: <DashOutlined />,
  DashboardFilled: <DashboardFilled />,
  DashboardOutlined: <DashboardOutlined />,
  DashboardTwoTone: <DashboardTwoTone />,
  DatabaseFilled: <DatabaseFilled />,
  DatabaseOutlined: <DatabaseOutlined />,
  DatabaseTwoTone: <DatabaseTwoTone />,
  DeleteColumnOutlined: <DeleteColumnOutlined />,
  DeleteFilled: <DeleteFilled />,
  DeleteOutlined: <DeleteOutlined />,
  DeleteRowOutlined: <DeleteRowOutlined />,
  DeleteTwoTone: <DeleteTwoTone />,
  DeliveredProcedureOutlined: <DeliveredProcedureOutlined />,
  DeploymentUnitOutlined: <DeploymentUnitOutlined />,
  DesktopOutlined: <DesktopOutlined />,
  DiffFilled: <DiffFilled />,
  DiffOutlined: <DiffOutlined />,
  DiffTwoTone: <DiffTwoTone />,
  DingdingOutlined: <DingdingOutlined />,
  DingtalkCircleFilled: <DingtalkCircleFilled />,
  DingtalkOutlined: <DingtalkOutlined />,
  DingtalkSquareFilled: <DingtalkSquareFilled />,
  DisconnectOutlined: <DisconnectOutlined />,
  DislikeFilled: <DislikeFilled />,
  DislikeOutlined: <DislikeOutlined />,
  DislikeTwoTone: <DislikeTwoTone />,
  DollarCircleFilled: <DollarCircleFilled />,
  DollarCircleOutlined: <DollarCircleOutlined />,
  DollarCircleTwoTone: <DollarCircleTwoTone />,
  DollarOutlined: <DollarOutlined />,
  DollarTwoTone: <DollarTwoTone />,
  DotChartOutlined: <DotChartOutlined />,
  DoubleLeftOutlined: <DoubleLeftOutlined />,
  DoubleRightOutlined: <DoubleRightOutlined />,
  DownCircleFilled: <DownCircleFilled />,
  DownCircleOutlined: <DownCircleOutlined />,
  DownCircleTwoTone: <DownCircleTwoTone />,
  DownOutlined: <DownOutlined />,
  DownSquareFilled: <DownSquareFilled />,
  DownSquareOutlined: <DownSquareOutlined />,
  DownSquareTwoTone: <DownSquareTwoTone />,
  DownloadOutlined: <DownloadOutlined />,
  DragOutlined: <DragOutlined />,
  DribbbleCircleFilled: <DribbbleCircleFilled />,
  DribbbleOutlined: <DribbbleOutlined />,
  DribbbleSquareFilled: <DribbbleSquareFilled />,
  DribbbleSquareOutlined: <DribbbleSquareOutlined />,
  DropboxCircleFilled: <DropboxCircleFilled />,
  DropboxOutlined: <DropboxOutlined />,
  DropboxSquareFilled: <DropboxSquareFilled />,
  EditFilled: <EditFilled />,
  EditOutlined: <EditOutlined />,
  EditTwoTone: <EditTwoTone />,
  EllipsisOutlined: <EllipsisOutlined />,
  EnterOutlined: <EnterOutlined />,
  EnvironmentFilled: <EnvironmentFilled />,
  EnvironmentOutlined: <EnvironmentOutlined />,
  EnvironmentTwoTone: <EnvironmentTwoTone />,
  EuroCircleFilled: <EuroCircleFilled />,
  EuroCircleOutlined: <EuroCircleOutlined />,
  EuroCircleTwoTone: <EuroCircleTwoTone />,
  EuroOutlined: <EuroOutlined />,
  EuroTwoTone: <EuroTwoTone />,
  ExceptionOutlined: <ExceptionOutlined />,
  ExclamationCircleFilled: <ExclamationCircleFilled />,
  ExclamationCircleOutlined: <ExclamationCircleOutlined />,
  ExclamationCircleTwoTone: <ExclamationCircleTwoTone />,
  ExclamationOutlined: <ExclamationOutlined />,
  ExpandAltOutlined: <ExpandAltOutlined />,
  ExpandOutlined: <ExpandOutlined />,
  ExperimentFilled: <ExperimentFilled />,
  ExperimentOutlined: <ExperimentOutlined />,
  ExperimentTwoTone: <ExperimentTwoTone />,
  ExportOutlined: <ExportOutlined />,
  EyeFilled: <EyeFilled />,
  EyeInvisibleFilled: <EyeInvisibleFilled />,
  EyeInvisibleOutlined: <EyeInvisibleOutlined />,
  EyeInvisibleTwoTone: <EyeInvisibleTwoTone />,
  EyeOutlined: <EyeOutlined />,
  EyeTwoTone: <EyeTwoTone />,
  FacebookFilled: <FacebookFilled />,
  FacebookOutlined: <FacebookOutlined />,
  FallOutlined: <FallOutlined />,
  FastBackwardFilled: <FastBackwardFilled />,
  FastBackwardOutlined: <FastBackwardOutlined />,
  FastForwardFilled: <FastForwardFilled />,
  FastForwardOutlined: <FastForwardOutlined />,
  FieldBinaryOutlined: <FieldBinaryOutlined />,
  FieldNumberOutlined: <FieldNumberOutlined />,
  FieldStringOutlined: <FieldStringOutlined />,
  FieldTimeOutlined: <FieldTimeOutlined />,
  FileAddFilled: <FileAddFilled />,
  FileAddOutlined: <FileAddOutlined />,
  FileAddTwoTone: <FileAddTwoTone />,
  FileDoneOutlined: <FileDoneOutlined />,
  FileExcelFilled: <FileExcelFilled />,
  FileExcelOutlined: <FileExcelOutlined />,
  FileExcelTwoTone: <FileExcelTwoTone />,
  FileExclamationFilled: <FileExclamationFilled />,
  FileExclamationOutlined: <FileExclamationOutlined />,
  FileExclamationTwoTone: <FileExclamationTwoTone />,
  FileFilled: <FileFilled />,
  FileGifOutlined: <FileGifOutlined />,
  FileImageFilled: <FileImageFilled />,
  FileImageOutlined: <FileImageOutlined />,
  FileImageTwoTone: <FileImageTwoTone />,
  FileJpgOutlined: <FileJpgOutlined />,
  FileMarkdownFilled: <FileMarkdownFilled />,
  FileMarkdownOutlined: <FileMarkdownOutlined />,
  FileMarkdownTwoTone: <FileMarkdownTwoTone />,
  FileOutlined: <FileOutlined />,
  FilePdfFilled: <FilePdfFilled />,
  FilePdfOutlined: <FilePdfOutlined />,
  FilePdfTwoTone: <FilePdfTwoTone />,
  FilePptFilled: <FilePptFilled />,
  FilePptOutlined: <FilePptOutlined />,
  FilePptTwoTone: <FilePptTwoTone />,
  FileProtectOutlined: <FileProtectOutlined />,
  FileSearchOutlined: <FileSearchOutlined />,
  FileSyncOutlined: <FileSyncOutlined />,
  FileTextFilled: <FileTextFilled />,
  FileTextOutlined: <FileTextOutlined />,
  FileTextTwoTone: <FileTextTwoTone />,
  FileTwoTone: <FileTwoTone />,
  FileUnknownFilled: <FileUnknownFilled />,
  FileUnknownOutlined: <FileUnknownOutlined />,
  FileUnknownTwoTone: <FileUnknownTwoTone />,
  FileWordFilled: <FileWordFilled />,
  FileWordOutlined: <FileWordOutlined />,
  FileWordTwoTone: <FileWordTwoTone />,
  FileZipFilled: <FileZipFilled />,
  FileZipOutlined: <FileZipOutlined />,
  FileZipTwoTone: <FileZipTwoTone />,
  FilterFilled: <FilterFilled />,
  FilterOutlined: <FilterOutlined />,
  FilterTwoTone: <FilterTwoTone />,
  FireFilled: <FireFilled />,
  FireOutlined: <FireOutlined />,
  FireTwoTone: <FireTwoTone />,
  FlagFilled: <FlagFilled />,
  FlagOutlined: <FlagOutlined />,
  FlagTwoTone: <FlagTwoTone />,
  FolderAddFilled: <FolderAddFilled />,
  FolderAddOutlined: <FolderAddOutlined />,
  FolderAddTwoTone: <FolderAddTwoTone />,
  FolderFilled: <FolderFilled />,
  FolderOpenFilled: <FolderOpenFilled />,
  FolderOpenOutlined: <FolderOpenOutlined />,
  FolderOpenTwoTone: <FolderOpenTwoTone />,
  FolderOutlined: <FolderOutlined />,
  FolderTwoTone: <FolderTwoTone />,
  FolderViewOutlined: <FolderViewOutlined />,
  FontColorsOutlined: <FontColorsOutlined />,
  FontSizeOutlined: <FontSizeOutlined />,
  ForkOutlined: <ForkOutlined />,
  FormOutlined: <FormOutlined />,
  FormatPainterFilled: <FormatPainterFilled />,
  FormatPainterOutlined: <FormatPainterOutlined />,
  ForwardFilled: <ForwardFilled />,
  ForwardOutlined: <ForwardOutlined />,
  FrownFilled: <FrownFilled />,
  FrownOutlined: <FrownOutlined />,
  FrownTwoTone: <FrownTwoTone />,
  FullscreenExitOutlined: <FullscreenExitOutlined />,
  FullscreenOutlined: <FullscreenOutlined />,
  FunctionOutlined: <FunctionOutlined />,
  FundFilled: <FundFilled />,
  FundOutlined: <FundOutlined />,
  FundProjectionScreenOutlined: <FundProjectionScreenOutlined />,
  FundTwoTone: <FundTwoTone />,
  FundViewOutlined: <FundViewOutlined />,
  FunnelPlotFilled: <FunnelPlotFilled />,
  FunnelPlotOutlined: <FunnelPlotOutlined />,
  FunnelPlotTwoTone: <FunnelPlotTwoTone />,
  GatewayOutlined: <GatewayOutlined />,
  GifOutlined: <GifOutlined />,
  GiftFilled: <GiftFilled />,
  GiftOutlined: <GiftOutlined />,
  GiftTwoTone: <GiftTwoTone />,
  GithubFilled: <GithubFilled />,
  GithubOutlined: <GithubOutlined />,
  GitlabFilled: <GitlabFilled />,
  GitlabOutlined: <GitlabOutlined />,
  GlobalOutlined: <GlobalOutlined />,
  GoldFilled: <GoldFilled />,
  GoldOutlined: <GoldOutlined />,
  GoldTwoTone: <GoldTwoTone />,
  GoldenFilled: <GoldenFilled />,
  GoogleCircleFilled: <GoogleCircleFilled />,
  GoogleOutlined: <GoogleOutlined />,
  GooglePlusCircleFilled: <GooglePlusCircleFilled />,
  GooglePlusOutlined: <GooglePlusOutlined />,
  GooglePlusSquareFilled: <GooglePlusSquareFilled />,
  GoogleSquareFilled: <GoogleSquareFilled />,
  GroupOutlined: <GroupOutlined />,
  HddFilled: <HddFilled />,
  HddOutlined: <HddOutlined />,
  HddTwoTone: <HddTwoTone />,
  HeartFilled: <HeartFilled />,
  HeartOutlined: <HeartOutlined />,
  HeartTwoTone: <HeartTwoTone />,
  HeatMapOutlined: <HeatMapOutlined />,
  HighlightFilled: <HighlightFilled />,
  HighlightOutlined: <HighlightOutlined />,
  HighlightTwoTone: <HighlightTwoTone />,
  HistoryOutlined: <HistoryOutlined />,
  HolderOutlined: <HolderOutlined />,
  HomeFilled: <HomeFilled />,
  HomeOutlined: <HomeOutlined />,
  HomeTwoTone: <HomeTwoTone />,
  HourglassFilled: <HourglassFilled />,
  HourglassOutlined: <HourglassOutlined />,
  HourglassTwoTone: <HourglassTwoTone />,
  Html5Filled: <Html5Filled />,
  Html5Outlined: <Html5Outlined />,
  Html5TwoTone: <Html5TwoTone />,
  IdcardFilled: <IdcardFilled />,
  IdcardOutlined: <IdcardOutlined />,
  IdcardTwoTone: <IdcardTwoTone />,
  IeCircleFilled: <IeCircleFilled />,
  IeOutlined: <IeOutlined />,
  IeSquareFilled: <IeSquareFilled />,
  ImportOutlined: <ImportOutlined />,
  InboxOutlined: <InboxOutlined />,
  InfoCircleFilled: <InfoCircleFilled />,
  InfoCircleOutlined: <InfoCircleOutlined />,
  InfoCircleTwoTone: <InfoCircleTwoTone />,
  InfoOutlined: <InfoOutlined />,
  InsertRowAboveOutlined: <InsertRowAboveOutlined />,
  InsertRowBelowOutlined: <InsertRowBelowOutlined />,
  InsertRowLeftOutlined: <InsertRowLeftOutlined />,
  InsertRowRightOutlined: <InsertRowRightOutlined />,
  InstagramFilled: <InstagramFilled />,
  InstagramOutlined: <InstagramOutlined />,
  InsuranceFilled: <InsuranceFilled />,
  InsuranceOutlined: <InsuranceOutlined />,
  InsuranceTwoTone: <InsuranceTwoTone />,
  InteractionFilled: <InteractionFilled />,
  InteractionOutlined: <InteractionOutlined />,
  InteractionTwoTone: <InteractionTwoTone />,
  IssuesCloseOutlined: <IssuesCloseOutlined />,
  ItalicOutlined: <ItalicOutlined />,
  KeyOutlined: <KeyOutlined />,
  LaptopOutlined: <LaptopOutlined />,
  LayoutFilled: <LayoutFilled />,
  LayoutOutlined: <LayoutOutlined />,
  LayoutTwoTone: <LayoutTwoTone />,
  LeftCircleFilled: <LeftCircleFilled />,
  LeftCircleOutlined: <LeftCircleOutlined />,
  LeftCircleTwoTone: <LeftCircleTwoTone />,
  LeftOutlined: <LeftOutlined />,
  LeftSquareFilled: <LeftSquareFilled />,
  LeftSquareOutlined: <LeftSquareOutlined />,
  LeftSquareTwoTone: <LeftSquareTwoTone />,
  LikeFilled: <LikeFilled />,
  LikeOutlined: <LikeOutlined />,
  LikeTwoTone: <LikeTwoTone />,
  LineChartOutlined: <LineChartOutlined />,
  LineHeightOutlined: <LineHeightOutlined />,
  LineOutlined: <LineOutlined />,
  LinkOutlined: <LinkOutlined />,
  LinkedinFilled: <LinkedinFilled />,
  LinkedinOutlined: <LinkedinOutlined />,
  Loading3QuartersOutlined: <Loading3QuartersOutlined />,
  LoadingOutlined: <LoadingOutlined />,
  LockFilled: <LockFilled />,
  LockOutlined: <LockOutlined />,
  LockTwoTone: <LockTwoTone />,
  LoginOutlined: <LoginOutlined />,
  LogoutOutlined: <LogoutOutlined />,
  MacCommandFilled: <MacCommandFilled />,
  MacCommandOutlined: <MacCommandOutlined />,
  MailFilled: <MailFilled />,
  MailOutlined: <MailOutlined />,
  MailTwoTone: <MailTwoTone />,
  ManOutlined: <ManOutlined />,
  MedicineBoxFilled: <MedicineBoxFilled />,
  MedicineBoxOutlined: <MedicineBoxOutlined />,
  MedicineBoxTwoTone: <MedicineBoxTwoTone />,
  MediumCircleFilled: <MediumCircleFilled />,
  MediumOutlined: <MediumOutlined />,
  MediumSquareFilled: <MediumSquareFilled />,
  MediumWorkmarkOutlined: <MediumWorkmarkOutlined />,
  MehFilled: <MehFilled />,
  MehOutlined: <MehOutlined />,
  MehTwoTone: <MehTwoTone />,
  MenuFoldOutlined: <MenuFoldOutlined />,
  MenuOutlined: <MenuOutlined />,
  MenuUnfoldOutlined: <MenuUnfoldOutlined />,
  MergeCellsOutlined: <MergeCellsOutlined />,
  MessageFilled: <MessageFilled />,
  MessageOutlined: <MessageOutlined />,
  MessageTwoTone: <MessageTwoTone />,
  MinusCircleFilled: <MinusCircleFilled />,
  MinusCircleOutlined: <MinusCircleOutlined />,
  MinusCircleTwoTone: <MinusCircleTwoTone />,
  MinusOutlined: <MinusOutlined />,
  MinusSquareFilled: <MinusSquareFilled />,
  MinusSquareOutlined: <MinusSquareOutlined />,
  MinusSquareTwoTone: <MinusSquareTwoTone />,
  MobileFilled: <MobileFilled />,
  MobileOutlined: <MobileOutlined />,
  MobileTwoTone: <MobileTwoTone />,
  MoneyCollectFilled: <MoneyCollectFilled />,
  MoneyCollectOutlined: <MoneyCollectOutlined />,
  MoneyCollectTwoTone: <MoneyCollectTwoTone />,
  MonitorOutlined: <MonitorOutlined />,
  MoreOutlined: <MoreOutlined />,
  NodeCollapseOutlined: <NodeCollapseOutlined />,
  NodeExpandOutlined: <NodeExpandOutlined />,
  NodeIndexOutlined: <NodeIndexOutlined />,
  NotificationFilled: <NotificationFilled />,
  NotificationOutlined: <NotificationOutlined />,
  NotificationTwoTone: <NotificationTwoTone />,
  NumberOutlined: <NumberOutlined />,
  OneToOneOutlined: <OneToOneOutlined />,
  OrderedListOutlined: <OrderedListOutlined />,
  PaperClipOutlined: <PaperClipOutlined />,
  PartitionOutlined: <PartitionOutlined />,
  PauseCircleFilled: <PauseCircleFilled />,
  PauseCircleOutlined: <PauseCircleOutlined />,
  PauseCircleTwoTone: <PauseCircleTwoTone />,
  PauseOutlined: <PauseOutlined />,
  PayCircleFilled: <PayCircleFilled />,
  PayCircleOutlined: <PayCircleOutlined />,
  PercentageOutlined: <PercentageOutlined />,
  PhoneFilled: <PhoneFilled />,
  PhoneOutlined: <PhoneOutlined />,
  PhoneTwoTone: <PhoneTwoTone />,
  PicCenterOutlined: <PicCenterOutlined />,
  PicLeftOutlined: <PicLeftOutlined />,
  PicRightOutlined: <PicRightOutlined />,
  PictureFilled: <PictureFilled />,
  PictureOutlined: <PictureOutlined />,
  PictureTwoTone: <PictureTwoTone />,
  PieChartFilled: <PieChartFilled />,
  PieChartOutlined: <PieChartOutlined />,
  PieChartTwoTone: <PieChartTwoTone />,
  PlayCircleFilled: <PlayCircleFilled />,
  PlayCircleOutlined: <PlayCircleOutlined />,
  PlayCircleTwoTone: <PlayCircleTwoTone />,
  PlaySquareFilled: <PlaySquareFilled />,
  PlaySquareOutlined: <PlaySquareOutlined />,
  PlaySquareTwoTone: <PlaySquareTwoTone />,
  PlusCircleFilled: <PlusCircleFilled />,
  PlusCircleOutlined: <PlusCircleOutlined />,
  PlusCircleTwoTone: <PlusCircleTwoTone />,
  PlusOutlined: <PlusOutlined />,
  PlusSquareFilled: <PlusSquareFilled />,
  PlusSquareOutlined: <PlusSquareOutlined />,
  PlusSquareTwoTone: <PlusSquareTwoTone />,
  PoundCircleFilled: <PoundCircleFilled />,
  PoundCircleOutlined: <PoundCircleOutlined />,
  PoundCircleTwoTone: <PoundCircleTwoTone />,
  PoundOutlined: <PoundOutlined />,
  PoweroffOutlined: <PoweroffOutlined />,
  PrinterFilled: <PrinterFilled />,
  PrinterOutlined: <PrinterOutlined />,
  PrinterTwoTone: <PrinterTwoTone />,
  ProfileFilled: <ProfileFilled />,
  ProfileOutlined: <ProfileOutlined />,
  ProfileTwoTone: <ProfileTwoTone />,
  ProjectFilled: <ProjectFilled />,
  ProjectOutlined: <ProjectOutlined />,
  ProjectTwoTone: <ProjectTwoTone />,
  PropertySafetyFilled: <PropertySafetyFilled />,
  PropertySafetyOutlined: <PropertySafetyOutlined />,
  PropertySafetyTwoTone: <PropertySafetyTwoTone />,
  PullRequestOutlined: <PullRequestOutlined />,
  PushpinFilled: <PushpinFilled />,
  PushpinOutlined: <PushpinOutlined />,
  PushpinTwoTone: <PushpinTwoTone />,
  QqCircleFilled: <QqCircleFilled />,
  QqOutlined: <QqOutlined />,
  QqSquareFilled: <QqSquareFilled />,
  QrcodeOutlined: <QrcodeOutlined />,
  QuestionCircleFilled: <QuestionCircleFilled />,
  QuestionCircleOutlined: <QuestionCircleOutlined />,
  QuestionCircleTwoTone: <QuestionCircleTwoTone />,
  QuestionOutlined: <QuestionOutlined />,
  RadarChartOutlined: <RadarChartOutlined />,
  RadiusBottomleftOutlined: <RadiusBottomleftOutlined />,
  RadiusBottomrightOutlined: <RadiusBottomrightOutlined />,
  RadiusSettingOutlined: <RadiusSettingOutlined />,
  RadiusUpleftOutlined: <RadiusUpleftOutlined />,
  RadiusUprightOutlined: <RadiusUprightOutlined />,
  ReadFilled: <ReadFilled />,
  ReadOutlined: <ReadOutlined />,
  ReconciliationFilled: <ReconciliationFilled />,
  ReconciliationOutlined: <ReconciliationOutlined />,
  ReconciliationTwoTone: <ReconciliationTwoTone />,
  RedEnvelopeFilled: <RedEnvelopeFilled />,
  RedEnvelopeOutlined: <RedEnvelopeOutlined />,
  RedEnvelopeTwoTone: <RedEnvelopeTwoTone />,
  RedditCircleFilled: <RedditCircleFilled />,
  RedditOutlined: <RedditOutlined />,
  RedditSquareFilled: <RedditSquareFilled />,
  RedoOutlined: <RedoOutlined />,
  ReloadOutlined: <ReloadOutlined />,
  RestFilled: <RestFilled />,
  RestOutlined: <RestOutlined />,
  RestTwoTone: <RestTwoTone />,
  RetweetOutlined: <RetweetOutlined />,
  RightCircleFilled: <RightCircleFilled />,
  RightCircleOutlined: <RightCircleOutlined />,
  RightCircleTwoTone: <RightCircleTwoTone />,
  RightOutlined: <RightOutlined />,
  RightSquareFilled: <RightSquareFilled />,
  RightSquareOutlined: <RightSquareOutlined />,
  RightSquareTwoTone: <RightSquareTwoTone />,
  RiseOutlined: <RiseOutlined />,
  RobotFilled: <RobotFilled />,
  RobotOutlined: <RobotOutlined />,
  RocketFilled: <RocketFilled />,
  RocketOutlined: <RocketOutlined />,
  RocketTwoTone: <RocketTwoTone />,
  RollbackOutlined: <RollbackOutlined />,
  RotateLeftOutlined: <RotateLeftOutlined />,
  RotateRightOutlined: <RotateRightOutlined />,
  SafetyCertificateFilled: <SafetyCertificateFilled />,
  SafetyCertificateOutlined: <SafetyCertificateOutlined />,
  SafetyCertificateTwoTone: <SafetyCertificateTwoTone />,
  SafetyOutlined: <SafetyOutlined />,
  SaveFilled: <SaveFilled />,
  SaveOutlined: <SaveOutlined />,
  SaveTwoTone: <SaveTwoTone />,
  ScanOutlined: <ScanOutlined />,
  ScheduleFilled: <ScheduleFilled />,
  ScheduleOutlined: <ScheduleOutlined />,
  ScheduleTwoTone: <ScheduleTwoTone />,
  ScissorOutlined: <ScissorOutlined />,
  SearchOutlined: <SearchOutlined />,
  SecurityScanFilled: <SecurityScanFilled />,
  SecurityScanOutlined: <SecurityScanOutlined />,
  SecurityScanTwoTone: <SecurityScanTwoTone />,
  SelectOutlined: <SelectOutlined />,
  SendOutlined: <SendOutlined />,
  SettingFilled: <SettingFilled />,
  SettingOutlined: <SettingOutlined />,
  SettingTwoTone: <SettingTwoTone />,
  ShakeOutlined: <ShakeOutlined />,
  ShareAltOutlined: <ShareAltOutlined />,
  ShopFilled: <ShopFilled />,
  ShopOutlined: <ShopOutlined />,
  ShopTwoTone: <ShopTwoTone />,
  ShoppingCartOutlined: <ShoppingCartOutlined />,
  ShoppingFilled: <ShoppingFilled />,
  ShoppingOutlined: <ShoppingOutlined />,
  ShoppingTwoTone: <ShoppingTwoTone />,
  ShrinkOutlined: <ShrinkOutlined />,
  SignalFilled: <SignalFilled />,
  SisternodeOutlined: <SisternodeOutlined />,
  SketchCircleFilled: <SketchCircleFilled />,
  SketchOutlined: <SketchOutlined />,
  SketchSquareFilled: <SketchSquareFilled />,
  SkinFilled: <SkinFilled />,
  SkinOutlined: <SkinOutlined />,
  SkinTwoTone: <SkinTwoTone />,
  SkypeFilled: <SkypeFilled />,
  SkypeOutlined: <SkypeOutlined />,
  SlackCircleFilled: <SlackCircleFilled />,
  SlackOutlined: <SlackOutlined />,
  SlackSquareFilled: <SlackSquareFilled />,
  SlackSquareOutlined: <SlackSquareOutlined />,
  SlidersFilled: <SlidersFilled />,
  SlidersOutlined: <SlidersOutlined />,
  SlidersTwoTone: <SlidersTwoTone />,
  SmallDashOutlined: <SmallDashOutlined />,
  SmileFilled: <SmileFilled />,
  SmileOutlined: <SmileOutlined />,
  SmileTwoTone: <SmileTwoTone />,
  SnippetsFilled: <SnippetsFilled />,
  SnippetsOutlined: <SnippetsOutlined />,
  SnippetsTwoTone: <SnippetsTwoTone />,
  SolutionOutlined: <SolutionOutlined />,
  SortAscendingOutlined: <SortAscendingOutlined />,
  SortDescendingOutlined: <SortDescendingOutlined />,
  SoundFilled: <SoundFilled />,
  SoundOutlined: <SoundOutlined />,
  SoundTwoTone: <SoundTwoTone />,
  SplitCellsOutlined: <SplitCellsOutlined />,
  StarFilled: <StarFilled />,
  StarOutlined: <StarOutlined />,
  StarTwoTone: <StarTwoTone />,
  StepBackwardFilled: <StepBackwardFilled />,
  StepBackwardOutlined: <StepBackwardOutlined />,
  StepForwardFilled: <StepForwardFilled />,
  StepForwardOutlined: <StepForwardOutlined />,
  StockOutlined: <StockOutlined />,
  StopFilled: <StopFilled />,
  StopOutlined: <StopOutlined />,
  StopTwoTone: <StopTwoTone />,
  StrikethroughOutlined: <StrikethroughOutlined />,
  SubnodeOutlined: <SubnodeOutlined />,
  SwapLeftOutlined: <SwapLeftOutlined />,
  SwapOutlined: <SwapOutlined />,
  SwapRightOutlined: <SwapRightOutlined />,
  SwitcherFilled: <SwitcherFilled />,
  SwitcherOutlined: <SwitcherOutlined />,
  SwitcherTwoTone: <SwitcherTwoTone />,
  SyncOutlined: <SyncOutlined />,
  TableOutlined: <TableOutlined />,
  TabletFilled: <TabletFilled />,
  TabletOutlined: <TabletOutlined />,
  TabletTwoTone: <TabletTwoTone />,
  TagFilled: <TagFilled />,
  TagOutlined: <TagOutlined />,
  TagTwoTone: <TagTwoTone />,
  TagsFilled: <TagsFilled />,
  TagsOutlined: <TagsOutlined />,
  TagsTwoTone: <TagsTwoTone />,
  TaobaoCircleFilled: <TaobaoCircleFilled />,
  TaobaoCircleOutlined: <TaobaoCircleOutlined />,
  TaobaoOutlined: <TaobaoOutlined />,
  TaobaoSquareFilled: <TaobaoSquareFilled />,
  TeamOutlined: <TeamOutlined />,
  ThunderboltFilled: <ThunderboltFilled />,
  ThunderboltOutlined: <ThunderboltOutlined />,
  ThunderboltTwoTone: <ThunderboltTwoTone />,
  ToTopOutlined: <ToTopOutlined />,
  ToolFilled: <ToolFilled />,
  ToolOutlined: <ToolOutlined />,
  ToolTwoTone: <ToolTwoTone />,
  TrademarkCircleFilled: <TrademarkCircleFilled />,
  TrademarkCircleOutlined: <TrademarkCircleOutlined />,
  TrademarkCircleTwoTone: <TrademarkCircleTwoTone />,
  TrademarkOutlined: <TrademarkOutlined />,
  TransactionOutlined: <TransactionOutlined />,
  TranslationOutlined: <TranslationOutlined />,
  TrophyFilled: <TrophyFilled />,
  TrophyOutlined: <TrophyOutlined />,
  TrophyTwoTone: <TrophyTwoTone />,
  TwitterCircleFilled: <TwitterCircleFilled />,
  TwitterOutlined: <TwitterOutlined />,
  TwitterSquareFilled: <TwitterSquareFilled />,
  UnderlineOutlined: <UnderlineOutlined />,
  UndoOutlined: <UndoOutlined />,
  UngroupOutlined: <UngroupOutlined />,
  UnlockFilled: <UnlockFilled />,
  UnlockOutlined: <UnlockOutlined />,
  UnlockTwoTone: <UnlockTwoTone />,
  UnorderedListOutlined: <UnorderedListOutlined />,
  UpCircleFilled: <UpCircleFilled />,
  UpCircleOutlined: <UpCircleOutlined />,
  UpCircleTwoTone: <UpCircleTwoTone />,
  UpOutlined: <UpOutlined />,
  UpSquareFilled: <UpSquareFilled />,
  UpSquareOutlined: <UpSquareOutlined />,
  UpSquareTwoTone: <UpSquareTwoTone />,
  UploadOutlined: <UploadOutlined />,
  UsbFilled: <UsbFilled />,
  UsbOutlined: <UsbOutlined />,
  UsbTwoTone: <UsbTwoTone />,
  UserAddOutlined: <UserAddOutlined />,
  UserDeleteOutlined: <UserDeleteOutlined />,
  UserOutlined: <UserOutlined />,
  UserSwitchOutlined: <UserSwitchOutlined />,
  UsergroupAddOutlined: <UsergroupAddOutlined />,
  UsergroupDeleteOutlined: <UsergroupDeleteOutlined />,
  VerifiedOutlined: <VerifiedOutlined />,
  VerticalAlignBottomOutlined: <VerticalAlignBottomOutlined />,
  VerticalAlignMiddleOutlined: <VerticalAlignMiddleOutlined />,
  VerticalAlignTopOutlined: <VerticalAlignTopOutlined />,
  VerticalLeftOutlined: <VerticalLeftOutlined />,
  VerticalRightOutlined: <VerticalRightOutlined />,
  VideoCameraAddOutlined: <VideoCameraAddOutlined />,
  VideoCameraFilled: <VideoCameraFilled />,
  VideoCameraOutlined: <VideoCameraOutlined />,
  VideoCameraTwoTone: <VideoCameraTwoTone />,
  WalletFilled: <WalletFilled />,
  WalletOutlined: <WalletOutlined />,
  WalletTwoTone: <WalletTwoTone />,
  WarningFilled: <WarningFilled />,
  WarningOutlined: <WarningOutlined />,
  WarningTwoTone: <WarningTwoTone />,
  WechatFilled: <WechatFilled />,
  WechatOutlined: <WechatOutlined />,
  WeiboCircleFilled: <WeiboCircleFilled />,
  WeiboCircleOutlined: <WeiboCircleOutlined />,
  WeiboOutlined: <WeiboOutlined />,
  WeiboSquareFilled: <WeiboSquareFilled />,
  WeiboSquareOutlined: <WeiboSquareOutlined />,
  WhatsAppOutlined: <WhatsAppOutlined />,
  WifiOutlined: <WifiOutlined />,
  WindowsFilled: <WindowsFilled />,
  WindowsOutlined: <WindowsOutlined />,
  WomanOutlined: <WomanOutlined />,
  YahooFilled: <YahooFilled />,
  YahooOutlined: <YahooOutlined />,
  YoutubeFilled: <YoutubeFilled />,
  YoutubeOutlined: <YoutubeOutlined />,
  YuqueFilled: <YuqueFilled />,
  YuqueOutlined: <YuqueOutlined />,
  ZhihuCircleFilled: <ZhihuCircleFilled />,
  ZhihuOutlined: <ZhihuOutlined />,
  ZhihuSquareFilled: <ZhihuSquareFilled />,
  ZoomInOutlined: <ZoomInOutlined />,
  ZoomOutOutlined: <ZoomOutOutlined />,
};
