import { notification } from 'antd';
import i18next from 'i18next';
import moment from 'moment';
import { Tag, Modal } from 'antd';
import { Icons } from 'constant/icons';

const helper = {};
const { confirm } = Modal;
helper.toast = (type = 'error', message, duration = 3) => {
  let className = 'noti-error';
  if (!message) message = 'Error';

  if (type === 'success') {
    if (!message) message = 'Success';
    className = 'noti-success';
  } else if (type === 'warning') {
    className = 'noti-warning';
  } else if (type === 'info') {
    className = 'noti-info';
  }
  notification[type]({
    message: i18next.t(message),
    duration,
    className,
    // placement: 'top',
  });
};
helper.tag = (status, className = '') => {
  let color = '',
    txt = status;
  if (status === 'isPaid') {
    status = 'Completed';
  }
  switch (status) {
    case 'Completed':
      color = 'green';
      break;
    case 'Pending':
      color = 'gold';
      break;
    default:
      color = 'red';
  }
  return (
    <Tag color={color} key={status} className={className + ' text-center'}>
      {i18n.t(txt).toUpperCase()}
    </Tag>
  );
};
helper.confirm = async (content, title = 'Confirm') => {
  return await new Promise((resolve, reject) => {
    confirm({
      title: i18next.t(title),
      icon: Icons.ExclamationCircleOutlined,
      content: content,
      okText: 'Confirm',
      onOk() {
        resolve(true);
      },

      onCancel() {
        reject(false);
      },
    });
  });
};
helper.convertBase64ToFile = (url, filename, mimeType) => {
  return fetch(url)
    .then(function (res) {
      return res?.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    })
    .catch((error) => {
      return console.log(error);
    });
};

helper.validateDatatype = (value, typeMask) => {
  switch (typeMask) {
    case 'number':
      return !isNaN(value);
    case 'integer':
      return !isNaN(value) && parseInt(value) === Number(value);
    case 'float':
    case 'double':
      return !isNaN(value) && parseFloat(value) === Number(value);
    case 'boolean':
      return (
        value === 'true' || value === 'false' || typeof value === 'boolean'
      );
    case 'string':
      return typeof value === 'string';
    case 'array':
      return Array.isArray(value);
    case 'json':
      return typeof value === 'object';
    case 'datetime':
      return moment(value).isValid();
  }
};
helper.parseDatatype = (value, typeMask) => {
  switch (typeMask) {
    case 'number':
      return Number(value);
    case 'integer':
      return parseInt(value);
    case 'float':
    case 'double':
      return parseFloat(value);
    case 'boolean':
      return value === 'true';
    case 'string':
      return value;
    case 'array':
    case 'json':
      return JSON.parse(value);
    case 'datetime':
      return moment(value);
  }
};
helper.getDatatype = (value) => {
  let format = 'string';
  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      format = 'array';
      value = JSON.stringify(value);
    } else {
      format = 'json';
      value = JSON.stringify(value);
    }
  } else if (typeof value === 'number') {
    if (isFloat(value)) {
      format = 'float';
    } else if (isInteger(value)) {
      format = 'integer';
    } else {
      format = 'number';
    }
  } else if (typeof value === 'boolean') {
    format = 'boolean';
  } else if (typeof value === 'string' && moment(value).isValid()) {
    format = 'datetime';
  }
  return { format, value };
};
export default helper;
const isFloat = (n) => {
  return n === +n && n !== (n | 0);
};
const isInteger = (n) => {
  return n === +n && n === (n | 0);
};
